<template>
	<div class="newtutorial">
		<article-list :article="cateArticle" :finished="finished" :loading="loading" :isShow="isShow"
			@getMore="getMore"></article-list>

		<div class="scrollTop" @click="backTop" v-if="btnFlag">
			<img src="@/assets/top.png" alt />
		</div>
	</div>
</template>

<script>
	import {
		SchoolClassIfyList
	} from "@/http/api";
	import ArticleList from "@/components/articles/ArticleList";

	export default {
		components: {
			ArticleList
		},
		data() {
			return {
				btnFlag: false,
				cate_id: 0,
				isShow: false,
				isLoad: true,
				pages: 1,
				loading: false,
				finished: false,
				cateArticle: []
			};
		},
		mounted() {
			window.addEventListener("scroll", this.scrollToTop);
			if (this.$route.query.id) {
				this.cate_id = this.$route.query.id
				this.SchoolClassIfyList();
			}
		},
		methods: {
			//回到顶部
			backTop() {
				const that = this;
				let timer = setInterval(() => {
					let ispeed = Math.floor(-that.scrollTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop =
						that.scrollTop + ispeed;
					if (that.scrollTop === 0) {
						clearInterval(timer);
					}
				}, 16);
			},

			//商学院分类列表
			async SchoolClassIfyList() {
				if (this.isLoad) {
					const res = await SchoolClassIfyList({
						data: {
							cate_id: this.cate_id,
							pages: this.pages,
							//user_token: this.$LStorage.getItem("userToken").userToken
						}
					});
					this.isShow = true;
					this.loading = false;
					if (res.numPage == res.data.length) {
						this.pages++;
						this.finished = false;
					} else {
						this.isLoad = false;
						this.finished = true;
					}
					this.cateArticle = this.cateArticle.concat(res.data);
				}
			},
			getMore() {
				this.SchoolClassIfyList();
			}
		},
		created() {
			document.title = this.$route.query.title;
		}
	};
</script>

<style lang="scss">
	.newtutorial {
		width: 100vw;
		height: 100vh;
		background: #f6f6f6;

		.my-top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 0.3rem;
			box-sizing: border-box;
			font-size: 0.38rem;
			background: #f4f4f4;
		}

		.scrollTop {
			position: fixed;
			bottom: 0.8rem;
			right: 0.5rem;

			img {
				width: 1.2rem;
				height: 1.2rem;
			}
		}
	}
</style>
