<!-- 商学院文章列表 -->
<template>
	<div class="school-wrap-list" v-if="isShow && article.length > 0">
		<van-list v-model="loading" :finished="finished" finished-text="到底啦〜想不到你看到了这里^_^" @load="getMore">
			<article-item :item="item" v-for="(item, index) in article" :key="index"></article-item>
		</van-list>
	</div>
	<no-data v-else></no-data>
</template>

<script>
	import ArticleItem from "./ArticleItem";

	export default {
		components: {
			ArticleItem,
		},
		props: {
			article: {
				type: Array,
				default () {
					return [];
				},
			},
			isShow: {
				type: Boolean,
				default () {
					return false;
				},
			},
			finished: {
				type: Boolean,
				default () {
					return false;
				},
			},
		},

		// 接收父组件参数
		data() {
			return {
				loading: false
			};
		},
		computed: {},
		//方法集合
		methods: {
			getMore() {
				this.$emit("getMore");
			},
			changeLoading(data) {
				this.loading = data;
			}
		},
	};
</script>
<style lang='scss' scoped>
	//@import url(); 引入公共css类
	.school-wrap-list {
		width: calc(100% - 0.6rem);
		margin: 0 auto;
		padding-top: 0.24rem;
	}
</style>
