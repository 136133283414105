<!--  -->
<template>
	<div>
		<router-link class="school-wrap-list-1" :to="'/graphicdetails?id=' + item.article_id" tag="div">
			<img :src="item.cover_imgurl" alt />

			<div class="school-wrap-list-1-r">
				<div class="school-wrap-list-1-r-top">
					<div>
						<span v-if="item.is_top == 1">置顶</span>
						{{ item.title }}
					</div>
				</div>

				<div class="school-wrap-list-1-r-bottom">
					<div>
						<svg t="1598581599388" class="icon" viewBox="0 0 1024 1024" version="1.1"
							xmlns="http://www.w3.org/2000/svg" p-id="2983" width="16" height="16">
							<path
								d="M511.913993 63.989249c-247.012263 0-447.924744 200.912481-447.924744 447.924744s200.912481 447.924744 447.924744 447.924744 447.924744-200.912481 447.924744-447.924744S758.926256 63.989249 511.913993 63.989249zM511.913993 895.677474c-211.577356 0-383.763481-172.186125-383.763481-383.763481 0-211.577356 172.014111-383.763481 383.763481-383.763481s383.763481 172.014111 383.763481 383.763481S723.491349 895.677474 511.913993 895.677474z"
								p-id="2984" fill="#b4b3b3" />
							<path
								d="M672.05913 511.913993l-159.973123 0L512.086007 288.123635c0-17.717453-14.277171-32.166639-31.994625-32.166639-17.717453 0-31.994625 14.449185-31.994625 32.166639l0 255.956996c0 17.717453 14.277171 31.994625 31.994625 31.994625l191.967747 0c17.717453 0 32.166639-14.277171 32.166639-31.994625C704.053754 526.191164 689.604569 511.913993 672.05913 511.913993z"
								p-id="2985" fill="#b4b3b3" />
						</svg>
						{{ item.create_time }}
					</div>

					<div>
						<svg t="1598581790338" class="icon" viewBox="0 0 1024 1024" version="1.1"
							xmlns="http://www.w3.org/2000/svg" p-id="3798" width="16" height="16">
							<path
								d="M512 643.134694c-72.097959 0-131.134694-58.514286-131.134694-131.134694S439.902041 380.865306 512 380.865306s131.134694 58.514286 131.134694 131.134694-59.036735 131.134694-131.134694 131.134694z m0-220.47347c-49.110204 0-89.338776 40.228571-89.338776 89.338776s40.228571 89.338776 89.338776 89.338776 89.338776-40.228571 89.338776-89.338776-40.228571-89.338776-89.338776-89.338776z"
								p-id="3799" fill="#b4b3b3" />
							<path
								d="M512 780.538776c-173.97551 0-321.828571-131.134694-394.44898-208.979592-30.82449-33.436735-30.82449-85.159184 0-118.595919 72.620408-77.844898 220.473469-208.979592 394.44898-208.979592s321.828571 131.134694 394.44898 208.979592c30.82449 33.436735 30.82449 85.159184 0 118.595919-72.620408 77.844898-220.473469 208.979592-394.44898 208.979592z m0-495.281633c-158.302041 0-295.706122 122.77551-364.146939 195.918367-16.195918 17.240816-16.195918 44.408163 0 61.64898 67.918367 73.142857 205.844898 195.918367 364.146939 195.918367s295.706122-122.77551 364.146939-195.918367c16.195918-17.240816 16.195918-44.408163 0-61.64898-68.440816-73.142857-205.844898-195.918367-364.146939-195.918367z"
								p-id="3800" fill="#b4b3b3" />
						</svg>
						{{ item.total_read }}
					</div>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			item: {
				type: Object,
				default () {
					return {};
				},
			},
		},
		// 接收父组件参数
		data() {
			return {};
		},
	};
</script>


<style lang='scss' scoped>
	//@import url(); 引入公共css类
	.school-wrap-list-1 {
		padding: 0.17rem 0.17rem 0.17rem 0.12rem;
		box-sizing: border-box;
		background: #fff;
		display: flex;
		margin-bottom: 0.26rem;

		img {
			width: 2.31rem;
			height: 1.64rem;
			display: block;
			border-radius: 0.1rem;
		}

		.school-wrap-list-1-r {
			margin-left: 0.2rem;
			width: calc(100% - 2.51rem);
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.school-wrap-list-1-r-top {
				// margin-bottom: 0.89rem;

				div {
					font-size: 0.28rem;
					line-height: 0.42rem;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;

					span {
						width: 0.8rem;
						height: 0.34rem;
						line-height: 0.34rem;
						text-align: center;
						border-radius: 0.2rem;
						background: #e50012;
						color: #ffffff;
						display: inline-block;
						font-size: 0.24rem;
						margin-right: 5px;
					}
				}
			}

			.school-wrap-list-1-r-bottom {
				display: flex;
				justify-content: space-between;

				div {
					display: flex;
					font-size: 0.22rem;
					color: #b4b3b3;
					align-items: center;

					svg {
						margin-right: 0.05rem;
					}
				}
			}
		}
	}
</style>
